@import url(https://fonts.googleapis.com/css2?family=Squada+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hind:wght@500&display=swap);
* {
  /* font-family: "Hind, sans-serif", sans-serif !important; */
  font-family: "Hind", sans-serif !important;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.request-popup .leaflet-popup-content-wrapper {
  border-radius: 0px;
  max-width: 500px;
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 20px;
  font: 20px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  background: transparent;
}

.leaflet-popup-content p {
  margin: 0;
}

::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 15px;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 15px;
  background: #d3d3d3;
}
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 15px;
}
::-webkit-scrollbar-button:single-button {
  display: none;
  border-style: none;
  height: 10px;
  width: 7px;
}

