@import url("https://fonts.googleapis.com/css2?family=Squada+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@500&display=swap");

* {
  /* font-family: "Hind, sans-serif", sans-serif !important; */
  font-family: "Hind", sans-serif !important;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
