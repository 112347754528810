.request-popup .leaflet-popup-content-wrapper {
  border-radius: 0px;
  max-width: 500px;
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 20px;
  font: 20px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  background: transparent;
}

.leaflet-popup-content p {
  margin: 0;
}
