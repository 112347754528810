::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 15px;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 15px;
  background: #d3d3d3;
}
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 15px;
}
::-webkit-scrollbar-button:single-button {
  display: none;
  border-style: none;
  height: 10px;
  width: 7px;
}
